import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Map from "../components/map"

import { Col, Image, Media } from "react-bootstrap"

import CocinasImage from "../images/reigas_imgs_1_cocinas.png"
import PerneriaImage from "../images/reigas_imgs_1_Pernos.png"
import TuberiaFerreteriaImage from "../images/reigas_imgs_ferrre.png"

import ReguladorImage from "../images/regulador_prod.png"
import CocinaImage from "../images/Cocina-prod.png"
import ValvulasImage from "../images/valvulas-gas.png"
import PernosImage from "../images/pernos-prod.png"

const IndexPage = () => (
  <Layout>
    <SEO title="Reguladores y Cocinas Industriales" />
    <Col>
      <h1>Reguladores y Cocinas Industriales</h1>
      <br />
      <div className="productItem">
        <Media>
          <img
            // width={150}
            height={150}
            className="mr-3"
            src={ReguladorImage}
            alt="Pernos"
          />
          <Media.Body>
            <h2>Reguladores Domésticos</h2>
            <p>
              Somos importadores exclusivos de la marca <b>REIGAS</b> para el
              Ecuador. Nuestros Reguladores tienen el <b>100% de garantía</b>,
              no pierde usted ni su cliente; Solicite hoy mismo un regulador
              Reigas, que utiliza la gente que sabe de gas.
            </p>
          </Media.Body>
        </Media>
      </div>

      <hr />

      <div className="productItem">
        <Media>
          <img
            // width={150}
            height={150}
            className="mr-3"
            src={ValvulasImage}
            alt="Válvulas para instalaciones de Gas"
          />
          <Media.Body>
            <h2>Válvulas para instalaciones de Gas</h2>

            <p>
              Disponemos en Stock válvulas para instalaciones domesticas e
              industriales.
            </p>
          </Media.Body>
        </Media>
      </div>

      <hr />

      <div className="productItem">
        <Media>
          <img
            // width={150}
            height={150}
            className="mr-3"
            src={CocinaImage}
            alt="Cocinas Industriales"
          />
          <Media.Body>
            <h2>Cocinas Industriales</h2>

            <p>
              Somos fabricantes de cocinas, hornos, freidoras, amasadoras,
              asadores de pollo a gas y carbón, autoservicios, molinos para
              granos y en general todo lo necesario para el equipamiento
              industrial de su negocio. <b>“Trabajamos sobre medida”</b>
            </p>
          </Media.Body>
        </Media>
      </div>

      <br />
      <h2>Más Información</h2>
      <p>
        Consulte nuestro{" "}
        <b>
          <a
            href="https://drive.google.com/file/d/1UVsYIaPgvrVWf9sRWwofPoCuWFPCO_Rl/view?usp=sharing"
            target="blank"
          >
            Catálogo de Cocinas
          </a>
        </b>{" "}
        y nuestro <b>Catálogo de Tacos, Tirafondos y Tornillos</b>.
      </p>
      <p>
        También puede revisar nuestro{" "}
        <a
          href="https://drive.google.com/open?id=11yYtqHOatXqN6mUY5jWUN7tk1OFrpaeG"
          target="blank"
        >
          <b>Catálogo completo de Representaciones Reinoso</b>
        </a>
        , o visitar nuestra sala de exhibición. Póngase en contacto con
        nosotros, gustosos en Atenderle.{" "}
      </p>

      <p>
        <ul>
          <li>
            <b>Telf:</b> +593 07-4076831
          </li>
          <li>
            <b>Cel:</b> +593 099 537 1857
          </li>
        </ul>
      </p>
    </Col>
  </Layout>
)

export default IndexPage
